import React from 'react'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <div className="inner">
                    <span className="icon major fa-microchip"></span>
                    <h1>Give your <strong>hardware</strong><br/>
                    dev team an <strong>edge</strong></h1>
                    <p> let <strong>s-tronics</strong> be your electronics development partner <br/>
                    and bring your technology to market faster </p>
                    <ul className="actions">
                        <li><a href="#services" className="button scrolly">Discover</a></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Header
